@mixin flex($align-items: center, $justify-content: center, $wrap: unset, $direction: row) {
  align-items: $align-items;
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify-content;
}

@mixin margin--auto {
  margin-left: auto;
  margin-right: auto;
}
