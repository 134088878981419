@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name)) + 1;
  }

  @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';

    @return null;
  }
}
$z-indexes: ('header', 'footer', 'carousel');

// Usage example
// .nav {
//   z-index: z('primary-navigation');
// }
