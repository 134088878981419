@import '../../../../assets/styles/settings/settings';
@import '../../../../assets/styles/utilities/utilities';
@import '../../../../assets/styles/generic/gen-keyframes';

.quadrant {
  @include media-breakpoint-up(md) {
    min-height: 1024px;
  }

  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      height: 100%;
      min-height: 100%;
      padding-bottom: 5rem;
      padding-top: 12rem;
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 800px;

    @media only screen and (max-height: 800px) {
      display: block;
    }
  }

  &__wrapper {
    max-width: 1300px;
  }

  &__title {
    @include h2;
  }

  &__subtitle {
    @extend %text--subtitle;
  }

  &__image {
    object-fit: cover;
  }

  &__description {
    font-size: 1.8rem;
    max-width: 606px;

    @include media-breakpoint-up(xl) {
      max-width: 640px;
    }
  }

  &__artists {
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 240px);
      grid-template-rows: repeat(2, 240px);

      @media only screen and (max-height: 1100px) {
        grid-template-columns: repeat(4, 170px);
        grid-template-rows: repeat(2, 170px);
      }
    }
  }

  &__artist {
    align-items: center;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 35px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 0;
      transform: translateX(50px);
    }

    &:first-of-type {
      @include media-breakpoint-up(md) {
        grid-column: span 4;
        grid-row: span 2;
        margin-bottom: 20px;
        margin-top: 0;
      }

      @include media-breakpoint-up(xl) {
        grid-column: span 2;
        margin-bottom: 0;
        transform: translateX(-50px);
      }

      .quadrant__artistLockup {
        &:first-of-type {
          @include media-breakpoint-between(md, lg) {
            margin-left: auto;
            margin-right: auto;
          }

          @include media-breakpoint-up(md) {
            height: 324px;
            width: 324px;
          }

          @include media-breakpoint-up(lg) {
            height: 424px;
            width: 424px;
          }

          @include media-breakpoint-up(xl) {
            height: 100%;
            width: 100%;
          }
        }

        &.noImage {
          min-width: 300px;

          @include media-breakpoint-up(md) {
            min-width: 400px;
          }
        }
      }
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}n) {
        .quadrant__image {
          animation-delay: #{$i * 0.15}s;
        }
      }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      .quadrant__artistName {
        font-size: 1.6rem;
        padding: 6px 7px;

        @include media-breakpoint-up(xl) {
          font-size: 2rem;
          padding: 7px 9px;
        }
      }
    }
  }

  &__artistName {
    background: var(--primary);
    bottom: 0;
    color: var(--secondary);
    font-size: 2.6rem;
    font-weight: $weight-bold;
    padding: 4.5px 19px;
    position: absolute;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-up(xl) {
      padding: 13.5px 19px;
    }
  }

  &__mobileList {
    background: var(--primary);
  }

  &__mobileItem {
    color: var(--secondary);
    font-size: 2.1rem;
    font-weight: bold;
  }

  &__gradient {
    height: 300px;
    object-fit: cover;
    opacity: 0;
    position: absolute;
    transform: scale(0.1) rotate(45deg);
    width: 300px;

    @include media-breakpoint-up(lg) {
      height: 400px;
      width: 400px;
    }

    @include media-breakpoint-up(xl) {
      height: 443px;
      width: 443px;
    }

    @media only screen and (max-height: 1100px) {
      height: 340px;
      width: 340px;
    }

    :global(.swiper-slide-active) & {
      animation: zoomOutFade 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      animation-delay: 0.5s;
    }
  }
}
