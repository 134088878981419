.f-sans {
  font-family: $font-spotify;
}

@font-face {
  font-family: 'Circular Spotify Head';
  font-style: normal;
  font-weight: bold;
  src:
    url('../../fonts/CircularSpotifyHead-Bold.woff2') format('woff2'),
    url('../../fonts/CircularSpotifyHead-Bold.woff') format('woff'),
    url('../../fonts/CircularSpotifyHead-Bold.svg#CircularSpotifyHead') format('svg');
}

@font-face {
  font-family: 'Circular Spotify Text';
  font-style: normal;
  font-weight: bold;
  src:
    url('../../fonts/CircularSpotifyText-Bold.woff2') format('woff2'),
    url('../../fonts/CircularSpotifyText-Bold.woff') format('woff'),
    url('../../fonts/CircularSpotifyText-Bold.svg#CircularSpotifyText') format('svg');
}

@font-face {
  font-family: 'Circular Spotify Text';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../fonts/CircularSpotifyText-Book.woff2') format('woff2'),
    url('../../fonts/CircularSpotifyText-Book.woff') format('woff'),
    url('../../fonts/CircularSpotifyText-Book.svg#CircularSpotifyText') format('svg');
}

@font-face {
  font-family: 'Circular Spotify Text';
  font-style: normal;
  font-weight: 300;
  src:
    url('../../fonts/CircularSpotifyText-Light.woff2') format('woff2'),
    url('../../fonts/CircularSpotifyText-Light.woff') format('woff'),
    url('../../fonts/CircularSpotifyText-Light.svg#CircularSpotifyText') format('svg');
}

@font-face {
  font-family: 'Circular Spotify Text';
  font-style: normal;
  font-weight: 600;
  src:
    url('../../fonts/CircularSpotifyText-Medium.woff2') format('woff2'),
    url('../../fonts/CircularSpotifyText-Medium.woff') format('woff'),
    url('../../fonts/CircularSpotifyText-Medium.svg#CircularSpotifyText') format('svg');
}
