//Image helpers

img {
  display: block;
  max-width: 100%;
}

.fit-image,
%fit-image {
  display: block;
  height: auto;
  width: 100%;
}

// IMAGE RATIO CLASSES
.image-ratio,
%image-ratio {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &--1 {
    padding-bottom: calc(100% * (281 / 291));

    @include media-breakpoint-up(md) {
      padding-bottom: calc(100% * (534 / 549));
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: calc(100% * (553 / 569));
    }
  }

  img {
    @include object-fit;
    height: 100%;
    left: 0;
    max-width: inherit;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

//object fit polyfill use on picture tags only
.objectfit,
%objectfit {
  height: 100%;

  img {
    bottom: 0;
    display: block;
    font-family: 'object-fit: cover;';
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

//Lazy loading transitions
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity $t-short;
}
