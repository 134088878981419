%button {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 3px solid rgba(var(--button-primary), 0);
  border-radius: 60px;
  color: var(--secondary);
  cursor: pointer;
  min-width: 146px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: all $t-short cubic-bezier(0.77, 0, 0.175, 1);
  user-select: none;

  > span {
    align-items: center;
    background-color: rgba(var(--button-primary), 1);
    border: none;
    border-radius: 60px;
    color: var(--secondary);
    display: flex;
    font-size: 1.8rem;
    font-weight: bold;
    justify-content: center;
    line-height: 110%;
    margin: 3px;
    min-height: 60px;
    padding: 10px 25px;
    pointer-events: none;
    text-align: center;
    transition: all $t-short cubic-bezier(0.77, 0, 0.175, 1);

    @include media-breakpoint-up(md) {
      font-size: $size-medium;
      padding: 10px 42px;
    }
  }

  &:focus {
    @include media-breakpoint-up(lg) {
      border-color: rgba(var(--button-primary), 1);

      > span {
        background-color: rgba(var(--button-primary), 1);
        color: var(--secondary);
      }
    }
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @media (pointer: fine), (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:hover {
      > span {
        background-color: rgba(var(--button-primary), 0.6);
      }
    }
  }

  &--transparent {
    background-color: transparent;

    > span {
      background-color: rgba(var(--button-primary), 0);
      border: 2px solid rgba(var(--button-primary), 1);
      color: var(--primary);
    }

    @media (pointer: fine), (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &:hover {
        span {
          border: 2px solid rgba(var(--button-primary), 0);
          color: var(--secondary);
        }
      }
    }
  }

  &--transparent-reverse {
    background-color: transparent;

    span {
      background-color: transparent;
      border: 2px solid rgba(var(--button-secondary), 1);
      color: var(--secondary);
    }

    @media (pointer: fine), (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &:hover {
        span {
          background-color: rgba(var(--button-primary), 1);
          border-color: rgba(var(--button-secondary), 1);
        }
      }
    }
  }

  &--lowercase {
    text-transform: initial;
  }
}

%cta {
  @extend %text--body;
  appearance: none;
  background: transparent;
  border: none;
  color: var(--primary);
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color $t-short ease;

  @media (pointer: fine), (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:hover {
    }
  }
}
