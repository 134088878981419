@import '../../assets/styles/generic/gen-keyframes';
@import '../../assets/styles/settings/settings';
@import '../../assets/styles/utilities/utilities';

$animationDuration: 1000ms;

@include media-breakpoint-up(lg) {
  .appearActive,
  .enterActive {
    animation-duration: $animationDuration;
    animation-fill-mode: both;
    animation-name: swiperSlideEnter;
  }

  .exitActive {
    animation-duration: $animationDuration;
    animation-fill-mode: both;
    animation-name: swiperSlideExit;
  }
}

:export {
  animationduration: $animationDuration;
}
