@import '../../../../assets/styles/settings/settings';
@import '../../../../assets/styles/utilities/utilities';

@keyframes fadeScale {
  0% {
    opacity: 0.2;
    transform: scale(0.9);
  }

  40% {
    opacity: 0.8;
    transform: scale(1.1);
  }

  75% {
    opacity: 1;
    transform: scale(0.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.multiSelect {
  max-width: 1300px;

  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      height: 100%;
      min-height: 100%;

      .multiSelect__buttonWrapper {
        max-width: 80%;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 800px;

    @media only screen and (max-height: 750px) {
      padding-top: 8rem;
    }
  }

  &__title {
    @include h2;
  }

  &__subtitle {
    @extend %text--subtitle;
  }

  &__description {
    font-size: 2rem;
    line-height: 2.2rem;

    @include media-breakpoint-up(md) {
      font-size: 3.6rem;
      line-height: 4.3rem;
    }
  }

  &__mobile {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('../../../../assets/img/down-arrow.svg') no-repeat;
    background-position: 94% 50%;
    background-size: 27px 13px;
    border: solid 3px var(--primary);
    border-radius: 72px;
    color: var(--primary);
    font-size: 2.6rem;
    font-weight: $weight-bold;
    line-height: 150%;
    min-height: 60px;
    padding: 8px 20px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__button {
    background-color: rgba(var(--button-primary), 0);
    border: solid 3px var(--primary);
    border-radius: 72px;
    color: var(--primary);
    cursor: pointer;
    font-size: 2.6rem;
    font-weight: $weight-bold;
    line-height: 150%;
    min-height: 60px;
    min-width: 146px;
    opacity: 0.2;
    overflow: hidden;
    padding: 10px 20px;
    position: relative;
    transform: scale(0.9);
    width: fit-content;

    &.selected {
      background-color: rgba(var(--button-primary), 1);
      color: var(--secondary);
    }

    @media (pointer: fine), (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &:hover:not(.selected) {
        background-color: rgba(var(--button-primary), 0.6);
        color: var(--secondary);
      }
    }

    :global(.swiper-slide-active) & {
      animation: fadeScale 0.7s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }

    @for $i from 1 through 13 {
      &:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.1}s !important;
      }
    }
  }

  &__buttonInput {
    display: none;

    &:checked + span {
      background-color: var(--primary);
      color: var(--secondary);
    }
  }
}
