@import '../../../../assets/styles/settings/settings';
@import '../../../../assets/styles/utilities/utilities';
@import '../../../../assets/styles/generic/gen-keyframes';

.quadtiles {
  max-width: 1300px;

  @include media-breakpoint-up(md) {
    min-height: 1024px;
  }

  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 850px;
  }

  &__title {
    @include h2;
  }

  &__subtitle {
    @extend %text--subtitle;
  }

  &__description {
    font-size: 1.8rem;
    max-width: 606px;

    @include media-breakpoint-up(xl) {
      max-width: 640px;
    }
  }

  &__mobileList {
    background: var(--primary);
  }

  &__mobileItem {
    color: var(--secondary);
    font-size: 2.1rem;

    &:first-of-type {
      font-weight: bold;
    }
  }

  &__podcast {
    @for $i from 1 through 5 {
      &:nth-child(#{$i}n) {
        .quadtiles__image,
        .quadtiles__text,
        .quadtiles__gradient {
          animation-delay: #{$i * 0.15}s !important;
        }
      }
    }

    &:nth-child(odd) {
      .quadtiles__gradient {
        border-radius: unset;
        height: 130px;
        min-width: 130px;

        @include media-breakpoint-up(lg) {
          height: 135px;
          min-width: 135px;
        }
      }
    }
  }

  &__imageWrapper {
    min-width: 120px;
    position: relative;

    @include media-breakpoint-down(md) {
      height: 130px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 150px;

      @media only screen and (max-height: 850px) {
        min-width: 130px;
      }
    }
  }

  &__image {
    height: 120px;
    min-width: 100%;

    @include media-breakpoint-up(lg) {
      height: 150px;

      @media only screen and (max-height: 850px) {
        height: 130px;
      }
    }
  }

  &__gradient {
    border-radius: 50%;
    height: 150px;
    left: 50%;
    min-width: 150px;
    object-fit: cover;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: scale(0.1) translate(-50%, -50%);

    @include media-breakpoint-up(lg) {
      height: 189px;
      min-width: 189px;

      @media only screen and (max-height: 850px) {
        height: 160px;
        min-width: 160px;
      }
    }

    :global(.swiper-slide-active) & {
      animation: zoomOutFadeRotateReverse 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }

  &__name {
    font-size: 2.2rem;
    font-weight: $weight-bold;
    max-width: 205px;
  }

  &__hosts {
    font-size: 1.8rem;
  }
}
