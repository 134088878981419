@import '../../../assets/styles/settings/settings';
@import '../../../assets/styles/utilities/utilities';

.cookieBar {
  background: $c-black;
  bottom: 0;
  left: 0;
  position: fixed;
  transform: translateY(100%);
  width: 100%;
  z-index: 350;

  &__wrapper {
    box-sizing: border-box;
    max-width: 1000px;
    padding: 17px 20px 14px;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin: 0 auto;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 auto 0 6rem;
      padding-left: 0;
    }
  }

  &__text {
    color: #545454;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    @media (min-width: 640px) {
      padding-right: 200px;
      text-align: left;
    }

    a {
      color: #878787;
      text-decoration: underline;
      transition: color 0.3s ease-in-out;
      white-space: nowrap;

      &:hover,
      &:active,
      &:focus {
        color: color('citric');
        text-decoration: underline;
      }
    }
  }

  &__button {
    background: transparent;
    border: 0;
    color: rgb(30, 215, 96);
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin: 16px 0 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 768px) {
      box-shadow: inset 0 4px 0 0 #1ed760;
      margin: 0;
      padding-top: 17px;
      position: absolute;
      right: 20px;
      top: 0;
      width: auto;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
  }
}
