.color,
%color {
  &--secondary {
    color: var(--secondary);
  }

  &--primary {
    color: var(--primary);
  }

  &--black {
    color: color('black');
  }

  &--white {
    color: color('white');
  }

  &--blue {
    color: color('blue');
  }

  &--aquamarine {
    color: color('aquamarine');
  }

  &--citric {
    color: color('citric');
  }

  &--spotify-black {
    color: color('spotify-black');
  }

  &--tangerine {
    color: color('tangerine');
  }

  &--fuschia {
    color: color('fuschia');
  }

  &--secondary-green {
    color: color('secondary-green');
  }

  &--secondary-blue {
    color: color('secondary-blue');
  }

  &--secondary-yellow {
    color: color('secondary-yellow');
  }
}

.bg,
%bg {
  &--secondary {
    background-color: var(--secondary);
  }

  &--primary {
    background-color: var(--primary);
  }

  &--black {
    background-color: color('black');
  }

  &--white {
    background-color: color('white');
  }

  &--blue {
    background-color: color('blue');
  }

  &--aquamarine {
    background-color: color('aquamarine');
  }

  &--citric {
    background-color: color('citric');
  }

  &--spotify-black {
    background-color: color('spotify-black');
  }

  &--tangerine {
    background-color: color('tangerine');
  }

  &--fuschia {
    background-color: color('fuschia');
  }

  &--secondary-green {
    background-color: color('secondary-green');
  }

  &--secondary-blue {
    background-color: color('secondary-blue');
  }

  &--secondary-yellow {
    background-color: color('secondary-yellow');
  }
}

.fill {
  &--secondary {
    fill: var(--secondary);
  }

  &--primary {
    fill: var(--primary);
  }
}
