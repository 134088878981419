@import '../../../../assets/styles/settings/settings';
@import '../../../../assets/styles/utilities/utilities';
@import '../../../../assets/styles/generic/gen-keyframes';

.formRight {
  max-width: 1300px;

  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      height: 100%;
      min-height: 100%;

      .formRight__wrapper {
        display: block;
      }

      .formRight__container {
        width: 100%;
      }

      .formRight__image {
        height: 380px;
        margin: 0 auto;
        min-width: 380px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 700px;
  }

  &__title {
    @include h2;
  }

  &__lockup {
    width: 100%;

    @include media-breakpoint-between(md, lg) {
      width: calc(50% - 10px);

      &:last-of-type {
        width: 100%;
      }
    }

    @for $i from 1 through 3 {
      &:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.1}s !important;
      }
    }
  }

  &__imageContainer {
    display: none;
    position: relative;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__image {
    animation-delay: 0.5s !important;

    @include media-breakpoint-up(sm) {
      height: 400px;
      min-width: 400px;
    }

    @include media-breakpoint-up(lg) {
      height: 450px;
      min-width: 450px;
    }

    @include media-breakpoint-up(xl) {
      height: 480px;
      min-width: 480px;
    }
  }
}
