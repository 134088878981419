$inputs: 'input[type=radio], input[type=checkbox], input[type=text], input[type=email], input[type=password], input[type=tel], select';

@mixin reset-block-model {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

@mixin field-font {
  @extend %text--body-1;
  letter-spacing: 0;

  @include media-breakpoint-up(lg) {
    font-size: $size-x-large;
  }
}

// Placeholders
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder,
::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
  color: rgba($c-blue, 0.5);
  overflow: visible;
}

form {
  .field-wrapper,
  .select-wrapper,
  .filter-select-wrapper {
    @include reset-block-model;
    margin-bottom: $medium;
    position: relative;

    &__text-input,
    #{$inputs} {
      @include reset-block-model;
    }
  }
}

input {
  background: transparent;
  border-bottom: 2px solid var(--primary);
  border-radius: 0;
  color: var(--primary);
  font-size: 1.8rem;
  height: 100%;
  padding-top: 15px;
  width: 100%;

  &.error {
    border-color: color($c-white);
  }

  @include media-breakpoint-up(xl) {
    width: 400px;
  }

  ~ label {
    color: var(--primary);
    left: 0;
    position: absolute;
    top: 0;
    transition: 0.3s;
    width: 100%;
    z-index: -1;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px var(--secondary) inset !important;
    -webkit-text-fill-color: var(--primary) !important;
    transition: background-color 5000s;
  }
}

input:focus ~ label,
.has-content ~ label {
  top: -18px;
  transition: 0.3s;
  z-index: 1;
}
