.image-carousel.swiper-container {
  .swiper-slide {
    transition: transform 0.3s ease-in-out;

    &--1 {
      &.swiper-slide {
        opacity: 0.7;
        transform: translate(-12px, -12px);
        z-index: 1;
      }

      &.swiper-slide.swiper-slide-prev {
        opacity: 0.6;
        transform: translate(-24px, -24px);
        z-index: 0;
      }
    }

    &--2 {
      &.swiper-slide.swiper-slide-next {
        opacity: 0.7;
        transform: translate(-12px, -12px);
        z-index: 1;
      }

      &.swiper-slide.swiper-slide-prev {
        opacity: 0.6;
        transform: translate(-24px, -24px);
        z-index: 0;
      }
    }

    &--3 {
      &.swiper-slide {
        opacity: 0.6;
        transform: translate(-24px, -24px);
        z-index: 0;
      }

      &.swiper-slide.swiper-slide-next {
        opacity: 0.7;
        transform: translate(-12px, -12px);
        z-index: 1;
      }
    }

    &.swiper-slide.swiper-slide-active {
      opacity: 1;
      transform: translate(0, 0);
      z-index: 2;
    }
  }
}

.main-board-carousel {
  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    @media only screen and (max-height: 650px) {
      height: 100%;
    }
  }
}
