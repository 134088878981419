@import '../../../../assets/styles/settings/settings';
@import '../../../../assets/styles/utilities/utilities';

.endScreen {
  // // TODO : Max height breaks anchor smooth scroll
  // max-height: 100vh;
  // overflow-y: auto;
  // scroll-snap-type: y mandatory;

  &__child {
    // scroll-snap-align: start;
    &:not(:last-of-type) {
      > div {
        @include media-breakpoint-up(md) {
          height: 97vh;
        }

        @include media-breakpoint-between(md, lg) {
          @media only screen and (max-height: 1100px) {
            height: 100%;
          }
        }
      }
    }
  }
}
