.ReactModal {
  &__Body--open {
    overflow: hidden;
  }

  &__Overlay {
    background: rgba($c-black, 0.5) !important;
    z-index: 10;
  }

  &__Content {
    animation: scaleUpTop 0.3s ease-in-out forwards;
    background-color: $c-citric !important;
    left: 50%;
    padding: 100px 50px 70px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    transition: all 0.3s ease;

    &.modal-calendar {
      animation: scaleUpCenter 0.3s ease-in-out forwards;
    }

    &:focus {
      outline: none;
    }

    @include media-breakpoint-down(sm) {
      padding: 50px 0;
      width: 90%;
    }

    div {
      display: flex;
      justify-content: center;
      margin-bottom: 100px;
      margin-top: 50px;

      @include media-breakpoint-down(sm) {
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
        margin-top: 50px;
      }
    }

    h2,
    a,
    p {
      color: $c-blue !important;
    }

    svg {
      height: 32px;
      position: absolute;
      right: 30px;
      top: 30px;
      width: 32px;

      circle {
        fill: $c-blue;
        stroke: $c-blue;
      }

      path {
        fill: $c-citric;
      }
    }

    a {
      font-size: 2rem;
      line-height: 2.2rem;
      margin-bottom: 10px;

      &:last-of-type {
        margin-right: 0;
      }

      @include media-breakpoint-up(md) {
        font-size: 3.6rem;
        line-height: 3.96rem;
        margin-bottom: 0;
        margin-right: 40px;
      }
    }

    button {
      background-color: $c-citric;
      color: $c-blue;

      span {
        background-color: $c-citric;
        border: 2px solid $c-blue;
        color: $c-blue;
      }

      &:hover {
        span {
          background-color: $c-blue;
          border: 2px solid $c-citric;
          color: $c-citric;
        }
      }

      &:focus {
        border-color: transparent;

        span {
          background-color: $c-blue;
          color: $c-citric;
        }
      }
    }
  }
}

@keyframes scaleUpCenter {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes scaleUpTop {
  0% {
    transform: translate(-50%, 0) scale(0);
  }

  100% {
    transform: translate(-50%, 0) scale(1);
  }
}
