@import '../../../../assets/styles/settings/settings';
@import '../../../../assets/styles/utilities/utilities';

.fullWidth {
  overflow: hidden;

  @include media-breakpoint-up(md) {
    min-height: 1024px;

    @media only screen and (max-height: 730px) {
      height: auto;
    }
  }

  &__logo {
    width: 213px;

    svg path {
      fill: var(--primary);
      transition: all $t-short ease;
    }

    @include media-breakpoint-up(md) {
      width: 300px;
    }
  }

  &__theme {
    z-index: -1;
  }

  &__description {
    font-size: 3.6rem;
    line-height: 3.96rem;
    max-width: 840px;

    @include media-breakpoint-up(xl) {
      max-width: 1000px;
    }
  }

  &__restartHeading {
    font-size: 4rem;
    line-height: 4.6rem;
    max-width: 840px;
    word-break: break-word;

    @include media-breakpoint-up(xl) {
      max-width: 1000px;
    }
  }

  &__restartLockup {
    background: var(--primary);
    position: relative;

    @include media-breakpoint-up(xl) {
      width: 1214px;
    }
  }

  &__restartLockupGradient {
    height: 88%;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: calc(100% + 60px);
    z-index: -1;
  }

  &__restartHeading {
    max-width: 550px;

    @include media-breakpoint-up(xl) {
      max-width: 100%;
    }
  }
}
