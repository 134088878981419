@import '../../assets/styles/settings/settings';
@import '../../assets/styles/utilities/utilities';
@import './animations';

.carousel {
  transition: opacity 1s linear;
  width: 100%;
  z-index: z('carousel');

  @include media-breakpoint-up(md) {
    height: calc(var(--vh, 1vh) * 100);
    min-height: 800px;
  }

  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      height: unset;
      max-height: 1200px;
      min-height: 900px;
    }
  }

  @include media-breakpoint-up(xl) {
    @media only screen and (max-height: 650px) {
      height: unset;
      max-height: 900px;
      min-height: 700px;
    }
  }

  &__hide {
    opacity: 0;
  }

  &__slide {
    background: transparent;
    position: relative;

    @include media-breakpoint-up(lg) {
      height: calc(var(--vh, 1vh) * 100);
      overflow: hidden;
    }

    @include media-breakpoint-between(md, lg) {
      @media only screen and (max-height: 1100px) {
        height: 100%;
      }
    }

    @include media-breakpoint-up(xl) {
      @media only screen and (max-height: 650px) {
        height: 100%;
      }
    }
  }

  &__endScreen {
    height: 100%;
    overflow: auto;
    width: 100vw;
  }
}
