// Base copy
@at-root html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.15;
  padding: env(safe-area-inset);
  -webkit-text-size-adjust: 100%;
}

@at-root body {
  font-family: $font-spotify;
  font-size: $size-small;
}

$typography: 'h1, h2, h3, h4, p, a,';

#{$typography} {
  color: var(--primary);
  transition: $t-short ease;
}

// Headings
h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4,
p {
  @extend %text--body;

  &.subheading {
    @extend %text--subheading;
  }
}

a {
  @extend %text--link;
  transition: 300ms cubic-bezier(0.77, 0, 0.175, 1);
  width: fit-content;

  &:hover {
    opacity: 1;
  }

  &.button {
    display: inline-block;
    opacity: 1;
  }

  &[disabled='disabled'],
  &.disabled {
    cursor: default;

    &:hover {
      opacity: 0.5;
    }
  }

  &.inline {
    @extend %inline--link;
  }
}

// Paragraph styles

.text {
  &--body {
    @extend %text--body;
  }

  &--small {
    @extend %text--small;
  }

  &--x-small {
    @extend %text--x-small;
  }
}

blockquote,
.blockquote {
  @include h4;
  display: block;
}

ul {
  @extend %text--body;
  list-style: none;

  &.bullet {
    @extend %bullet;
  }
}
