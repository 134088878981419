// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: (
  xs: 0,
  sm: 560px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1500px,
  xxxl: 1800px,
  xxxxl: 2000px,
  xxxxxl: 3000px,
) !default;

/// BELOW EXPORTS THESE BREAKPOINTS AS VARIABLES FOR JS USE

@each $size, $value in $grid-breakpoints {
  :export {
    BREAKPOINT_#{$size}: strip-unit($value);
  }
}
