@import '../../../../assets/styles/settings/settings';
@import '../../../../assets/styles/utilities/utilities';
@import '../../../../assets/styles/generic/gen-keyframes';

.image {
  @include media-breakpoint-up(md) {
    min-height: 1024px;
  }

  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      height: 100%;
      min-height: 100%;

      .image__description {
        margin-bottom: 4rem;
      }

      .image__phoneWrapper {
        margin-top: 0;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 800px;
  }

  &__wrapper {
    max-width: 1300px;
  }

  &__theme {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &__headings {
    max-width: 705px;

    @include media-breakpoint-up(xl) {
      max-width: 640px;
    }

    h2,
    p {
      color: var(--primary);

      :global(.swiper-slide--1) & {
        position: relative;
      }
    }

    p {
      @for $i from 1 through 5 {
        &:nth-child(#{$i}n) {
          animation-delay: #{$i * 0.2}s !important;
        }
      }
    }

    a {
      animation-delay: 0.4s !important;
    }
  }

  &__title {
    @include h2;
  }

  &__subtitle {
    @extend %text--subtitle;

    @include media-breakpoint-down(md) {
      transform: translateX(0);
    }
  }

  &__description {
    font-size: 2rem;
    line-height: 2.2rem;
    position: relative;

    @include media-breakpoint-up(md) {
      font-size: 3.6rem;
      line-height: 4.3rem;
      position: unset;
    }
  }

  &__descriptionMoments {
    @include media-breakpoint-up(xl) {
      @media only screen and (max-height: 800px) {
        font-size: 3rem;
        line-height: 3.8rem;
      }
    }
  }

  &__descriptionSmall {
    font-size: 1.6rem;
    line-height: 1.8rem;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }

  &__gradientWrapper {
    height: 230px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 230px;

    @include media-breakpoint-up(md) {
      height: 290px;
      width: 290px;
    }

    @include media-breakpoint-up(lg) {
      height: 340px;
      width: 340px;
    }

    @include media-breakpoint-up(xl) {
      height: 400px;
      width: 400px;
    }
  }

  &__gradient {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transform: scale(0.1) rotate(45deg) translate(-50%, -50%);
    width: 100%;

    :global(.swiper-slide-active) & {
      animation: zoomOutFadeSpin 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      animation-delay: 0.5s;
    }
  }

  &__imageContainer {
    position: relative;
  }

  &__image {
    animation-delay: 0.5s !important;
    height: 280px;
    position: relative;
    width: 280px;

    @include media-breakpoint-up(md) {
      height: 350px;
      opacity: 0;
      position: unset;
      width: 350px;
    }

    @include media-breakpoint-up(lg) {
      height: 480px;
      width: 480px;
    }
  }

  &__anchor {
    border: 1px solid var(--primary);
    border-radius: 50%;
    bottom: 28px;
    cursor: pointer;
    left: 50%;
    padding: 10px;
    position: absolute;
    transform: translateX(-50%);

    svg {
      height: 18px;
      transform: rotate(180deg);
      width: 18px;

      path {
        fill: var(--primary);
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__carousel {
    overflow: visible;
    transform: translateX(15px);
    width: 280px;

    @include media-breakpoint-up(sm) {
      width: 300px;
    }

    @include media-breakpoint-up(lg) {
      width: 480px;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 0;
      transform: translateX(0);
    }
  }

  &__carouselSlide {
    width: 0 !important;
  }

  &__carouselSlideWrapper {
    height: 280px;
    width: 280px;

    @include media-breakpoint-up(sm) {
      height: 300px;
      width: 300px;
    }

    @include media-breakpoint-up(lg) {
      height: 480px;
      width: 480px;
    }
  }

  &__carouselImage {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__phoneWrapper {
    align-items: flex-end;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    height: 300px;
    justify-content: center;
    margin: 0 auto;
    margin-top: -50px;
    transform: scale(1.4);
    width: 300px;

    @include media-breakpoint-up(lg) {
      height: 600px;
      margin-top: -150px;
      width: 600px;
    }

    @include media-breakpoint-up(xl) {
      height: 700px;
      margin-top: -50px;
      width: 700px;

      @media only screen and (max-height: 800px) {
        height: 600px;
        margin-top: -150px;
        width: 600px;
      }
    }

    :global(.swiper-slide-active) & {
      animation: zoomInFade 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      animation-delay: 0.5s;
    }
  }

  &__gradientClip {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    height: 250px;
    position: absolute;
    width: 250px;

    @include media-breakpoint-up(lg) {
      height: 530px;
      width: 530px;
    }

    @include media-breakpoint-up(xl) {
      height: 615px;
      width: 615px;

      @media only screen and (max-height: 800px) {
        height: 530px;
        width: 530px;
      }
    }
  }

  &__mobileList {
    background: var(--primary);
    position: relative;
  }

  &__mobileItem {
    color: var(--secondary);
    font-size: 2.1rem;
    font-weight: bold;
  }

  &__whiteBg {
    background: $c-white;
    border-radius: 30px 30px 0 0;
    height: 210px;
    position: relative;
    transform: translateY(100%);
    width: 180px;

    @include media-breakpoint-up(lg) {
      border-radius: 50px 50px 0 0;
      height: 450px;
      width: 344px;
    }

    @include media-breakpoint-up(xl) {
      height: 520px;

      @media only screen and (max-height: 800px) {
        height: 450px;
      }
    }

    :global(.swiper-slide-active) & {
      animation: slideUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      animation-delay: 0.5s;
    }
  }

  &__button {
    @include media-breakpoint-down(md) {
      span {
        font-size: 2.1rem;
      }
    }
  }

  &__colorBg {
    background: var(--primary);
    border-radius: 4px;
    height: 230px;
    left: 50%;
    position: absolute;
    top: 20px;
    transform: translateX(-50%);
    width: 85%;

    @include media-breakpoint-up(lg) {
      height: 515px;
      top: 55px;
    }
  }

  &__screen {
    display: flex;
    flex-direction: column;
    left: 50%;
    position: absolute;
    text-align: left;
    top: 30px;
    transform: translateX(-50%);
    width: 75%;

    p {
      color: $c-white;
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 25px;

      @include media-breakpoint-down(md) {
        font-size: 1.2rem;
        margin-bottom: 8px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      top: 65px;
    }
  }

  &__gradient {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__white {
    .image__screen {
      color: $c-white;
    }
  }

  &__black {
    .image__screen {
      color: $c-black;
    }
  }
}
