@mixin h1 {
  font-family: $font-heading;
  font-size: $size-bigger;
  font-weight: $weight-bold;
  line-height: 110%;

  @include media-breakpoint-up(lg) {
    font-size: $size-huge;
  }
}

@mixin h2 {
  font-family: $font-heading;
  font-size: 4.6rem;
  font-weight: $weight-semi-bold;
  line-height: 5rem;

  @include media-breakpoint-up(md) {
    font-size: $size-xx-large;
    line-height: 110%;
  }
}

@mixin h3 {
  font-family: $font-heading;
  font-size: $size-large;
  font-weight: $weight-bold;
  line-height: 120%;

  @include media-breakpoint-up(lg) {
    font-size: $size-x-large; // 30px
  }
}

@mixin h4 {
  font-family: $font-heading;
  font-size: $size-medium;
  font-weight: normal;
  line-height: 150%;
}

%text--body {
  font-size: $size-medium;
  font-weight: normal;
  line-height: 150%;
}

%text--subtitle {
  font-size: $size-default * 2;
  font-weight: $weight-regular;
  line-height: 3.52rem;
  text-transform: uppercase;
}

%text--small {
  font-size: $size-small;
  font-weight: normal;
  line-height: 120%;
}

%text--x-small {
  font-size: $size-x-small;
  font-weight: normal;
  line-height: 120%;
}

%text--link {
  font-size: 1.5rem;
  font-weight: $weight-medium;
  line-height: 4rem;

  @include media-breakpoint-up(md) {
    font-size: 1.8rem;
    line-height: $size-big;
  }
}

%inline--link {
  font-size: $size-small;
  font-weight: lighter;
  line-height: 1.5rem;
  position: relative;

  @include media-breakpoint-up(lg) {
    font-size: $size-small; // 16px
    line-height: 1.3;
  }

  @media (pointer: fine), (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:hover {
      &:after {
        opacity: 1;
        width: 100%;
      }
    }
  }
}

%text--subheading {
  @include h3;
}

%bullet {
  padding-left: 20px;

  li {
    position: relative;

    &:before {
      content: '\2022';
      display: inline-block;
      height: 4px;
      left: -20px;
      margin-right: 5px;
      position: absolute;
      width: 4px;
    }
  }
}
