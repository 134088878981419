@import '../../../assets/styles/settings/settings';
@import '../../../assets/styles/utilities/utilities';

.loader {
  background: color('spotify-black');
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 50;
}

.loaderAnimation {
  height: auto;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;

  @include media-breakpoint-up(md) {
    width: 500px;
  }
}
