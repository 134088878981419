@import './gen-keyframes';

.swiper-slide-next {
  &.swiper-slide {
    height: 0;

    @include media-breakpoint-up(lg) {
      height: unset;
    }
  }

  .quadtile-image,
  .quadtile-text,
  .image-right-lockup-image,
  .heading-image-right p {
    @include media-breakpoint-up(md) {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }
}

.swiper-slide-active {
  .quadrant-image {
    animation: slideUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    transform: translate3d(0, 100%, 0);
  }

  .quadtile-image,
  .quadtile-text,
  .form-input {
    animation: revealUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  .image-right-lockup-image,
  .heading-image-right p,
  .heading-image-right a,
  .heading {
    @include media-breakpoint-up(md) {
      animation: revealUp 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      opacity: 0;
      transform: translate3d(0, 150px, 0);
    }
  }
}

.text-block-animation {
  .swiper-slide-active & {
    overflow: hidden;
    position: relative;

    h1,
    h2,
    p {
      animation: fadeIn 0s 0.6s;
      animation-fill-mode: backwards;
    }

    &:after {
      animation: blockIn 0.6s 0s, blockOut 0.6s 0.6s;
      animation-fill-mode: both;
      background: var(--secondary);
      content: '';
      height: 100%;
      left: 1px;
      position: absolute;
      top: 0;
      transform: translateX(-105%);
      width: 100%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slideOut {
  &.animate {
    transform: translate3d(100%, 0, 0);

    &.animate-active {
      animation: slideOut $t-short cubic-bezier(0.8, 0, 0, 0.8) forwards;
      animation-delay: $t-medium;
    }
  }
}

.slideOut {
  &.animate {
    transform: translate3d(100%, 0, 0);

    &.animate-active {
      animation: slideOut 0.4s cubic-bezier(0.8, 0, 0, 0.8) forwards;
      animation-delay: 0.5s;
    }
  }
}

.revealUp {
  will-change: transform, opacity;

  &.animate {
    opacity: 0;
    transform: translate3d(0, 100px, 0);

    &.animate-active {
      animation: revealUp 1.5s ease forwards;
    }
  }
}

.slideUp {
  will-change: transform, opacity;

  &.animate {
    // opacity: 0;

    &.animate-active {
      animation: slideUp 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
}

// TODO: Switch out delays for animations into a different class so we do not have delays on all animations because not all fades need a delay
.fadeIn {
  &.animate {
    opacity: 0;
    transition: opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.animate-active {
      animation: fadeIn 0.7s both;
      animation-delay: 0.3s;
    }
  }
}

.fadeOutUp {
  &.animate {
    opacity: 1;
    transition: all 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.animate-active {
      opacity: 0;
      transform: translate3d(0, -100vh, 0);
      transform-origin: top center;
      transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
      will-change: transform;
    }
  }
}

.fadeInUp {
  &.animate {
    opacity: 0;
    transform: translate3d(0, 100vh, 0);
    transition: all 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.animate-active {
      opacity: 1;
      transform: translate3d(0, 5vh, 0);
      // transform-origin: top center;
      transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
      will-change: transform;
    }
  }
}

.fadeInZoom {
  will-change: transform;

  &.animate {
    transform: scale(1.4);
    transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.animate-active {
      animation: zoomInFade 1.5s forwards;
      animation-delay: 0.5s;
    }
  }
}

.imageSlideUp {
  &.animate {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    transform-origin: top center;
    transition: opacity 1.2s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0.3s;
    will-change: transform;

    &.animate-active {
      opacity: 1;
      transform: scaleY(1) translateY(0);
    }
  }
}

.flowIn {
  will-change: transform, opacity;

  &.animate {
    margin-left: 100%;

    &.animate-active {
      animation: flowIn 1.5s forwards;
      animation-delay: 0.5s;
    }
  }
}

.appearIn {
  will-change: width;

  &.animate {
    width: 0;

    &.animate-active {
      animation: appearIn 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    }
  }
}
