@import '../../../assets/styles/settings/settings';
@import '../../../assets/styles/utilities/utilities';

.footer {
  transition: transform 1s ease;
  z-index: z('footer');

  @include media-breakpoint-up(md) {
    margin-top: -107px;
    padding-bottom: 35px;
  }

  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      margin-top: 0;
    }
  }

  &__navigation {
    position: relative;
  }

  &__hide {
    transform: translateY(100%);
  }
}

.pagination {
  $root: &;
  min-height: 35px;
  position: relative;

  &__dot {
    background-color: var(--primary);
    border-radius: 50%;
    display: block;
    height: 10px;
    opacity: 0.5;
    position: relative;
    transition: all $t-short ease;
    width: 10px;

    .active & {
      opacity: 1;
    }
  }

  &__item {
    margin-left: 16px;
    margin-right: 16px;
    position: relative;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      #{$root}__title {
        opacity: 1;
        pointer-events: all;
        transform: translate(-50%, 0);
        transition-delay: 0.3s;
      }

      #{$root}__dot {
        opacity: 1;
        transform: translateY(-20%);
      }
    }
  }

  &__title {
    @extend %text--x-small;
    left: 50%;
    letter-spacing: 0.03rem;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    top: -30px;
    transform: translate(-50%, 10%);
    transition: all $t-short ease;
    width: max-content;
  }
}
