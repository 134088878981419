@import '../../../assets/styles/settings/settings';
@import '../../../assets/styles/utilities/utilities';

.header {
  overflow: hidden;
  transition: background-color $t-short ease, transform 1s ease;
  z-index: z('header');

  &__hide {
    transform: translateY(-100%);
  }

  &__logo {
    width: 213px;

    svg path {
      fill: var(--primary);
      transition: all $t-short ease;
    }

    @include media-breakpoint-up(md) {
      width: 300px;
    }
  }

  &__menu {
    height: 32px;
  }

  &__menuButton {
    width: 32px;

    &:focus:not(:focus-visible) {
      outline: none;
    }

    svg {
      path {
        fill: var(--secondary);
        transition: all $t-short ease;
      }

      circle {
        fill: var(--primary);
        stroke: var(--primary);
        transition: all $t-short ease;
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__menuButtonModal {
    width: 32px;

    &:focus:not(:focus-visible) {
      outline: none;
    }

    svg {
      path {
        fill: var(--secondary);
        transition: all $t-short ease;
      }

      circle {
        fill: var(--primary);
        stroke: var(--primary);
        transition: all $t-short ease;
      }
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__menuLinks {
    opacity: 0;
    pointer-events: none;
    right: 100%;
    top: 50%;
    transform: translateY(-40%);
    transition: all $t-short ease;
    width: fit-content;

    a,
    p {
      display: block;
      font-size: 1.2rem;
      line-height: 120%;
      margin-right: 1rem;
      text-transform: uppercase;
      white-space: nowrap;

      @include media-breakpoint-up(lg) {
        margin-right: 2rem;
      }
    }

    &.open {
      opacity: 1;
      pointer-events: all;
    }

    &.modalOpen {
      opacity: 1;
      right: unset;
      top: unset;
      transform: translateY(0);

      a {
        // color: var(--primary);
        font-size: 3.6rem;
        margin-bottom: 30px;
        text-transform: initial;
      }

      p {
        font-size: 1.6rem;
        margin-bottom: 60px;
        margin-top: 40px;
      }
    }
  }

  &__modal {
    height: 500px;
    padding: 78px 35px;
    text-align: left;
    top: 22px;
    transform: translate(0, 0);
  }
}
