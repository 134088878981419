// Sans font
$font-spotify: 'Circular Spotify Text', Helvetica, sans-serif;
$font-heading: 'Circular Spotify Head', Helvetica, sans-serif;

// Serif font
$font-serif: 'Times';

@charset "UTF-8";

// Default Fonts Size Variables
$size-default: 1.6rem;

$size-huge: 8.6rem; // H1 >1024
$size-bigger: 7.6rem; // H1 <1024
$size-big: 6.4rem; // H2 >1024
$size-xx-large: 5.7rem; // H2 <1024
$size-x-large: 4.8rem; // H3 >1024
$size-large: 4.2rem; // H3 <1024
$size-medium: 2.4rem; // small body copy
$size-small: $size-default; // small body copy
$size-x-small: 1.4rem; // x small body copy

// Font Weight
$weight-bold: bold;
$weight-semi-bold: 600;
$weight-medium: 500;
$weight-regular: 400;
$weight-light: 300;
