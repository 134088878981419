$xxs: 5px;
$xs: 14px;
$small: 24px;
$medium: 32px;
$large: 48px;
$xlarge: 60px;
$xxlarge: 72px;
$huge: 96px;
$xhuge: 110px;

$padding-default: 20px;
