.button {
  @extend %button;

  &--hide {
    display: none;
  }

  &--transparent {
    @extend %button--transparent;
  }

  &--transparent-reverse {
    @extend %button--transparent-reverse;
  }

  &--lowercase {
    @extend %button--lowercase;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--small {
    @include media-breakpoint-down(md) {
      > span {
        font-size: 1.6rem;
        line-height: 110%;
      }
    }
  }
}

.cta {
  @extend %cta;
}
