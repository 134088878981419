// CSS Vars setup
:root {
  @each $name, $color in $colors {
    #{$color-prefix}#{$name}: $color;
  }

  --primary: #{$c-tangerine};
  --secondary: #{$c-spotify-black};
  --button-primary: #{$rgb-tangerine};
  --button-secondary: #{$rgb-spotify-black};
}

// theme definitions
[data-theme='0'] {
  --primary: #{$c-citric};
  --secondary: #{$c-blue};
  --button-primary: #{$rgb-citric};
  --button-secondary: #{$rgb-blue};
}

[data-theme='1'] {
  --primary: #{$c-fuschia};
  --secondary: #{$c-aquamarine};
  --button-primary: #{$rgb-fuschia};
  --button-secondary: #{$rgb-aquamarine};
}

[data-theme='2'] {
  --primary: #{$c-spotify-black};
  --secondary: #{$c-tangerine};
  --button-primary: #{$rgb-spotify-black};
  --button-secondary: #{$rgb-tangerine};
}

[data-theme='3'] {
  --primary: #{$c-blue};
  --secondary: #{$c-citric};
  --button-primary: #{$rgb-blue};
  --button-secondary: #{$rgb-citric};
}

[data-theme='4'] {
  --primary: #{$c-spotify-black};
  --secondary: #{$c-tangerine};
  --button-primary: #{$rgb-spotify-black};
  --button-secondary: #{$rgb-tangerine};
}

[data-theme='5'] {
  --primary: #{$c-tangerine};
  --secondary: #{$c-spotify-black};
  --button-primary: #{$rgb-tangerine};
  --button-secondary: #{$rgb-spotify-black};
}
