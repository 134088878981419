@keyframes blockIn {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes blockOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(103%);
  }
}

@keyframes slideLeft {
  0% {
    transform: translate3d(25%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideRight {
  0% {
    transform: translate3d(-25%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes revealUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes revealUpLarge {
  0% {
    opacity: 0;
    transform: translate3d(0, 150px, 0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translate3d(0, 100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomOutFade {
  0% {
    opacity: 0;
    transform: scale(0.1) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}

@keyframes zoomOutFadeTransform {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes zoomOutFadeTransformReverse {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%) rotate(180deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(180deg);
  }
}

@keyframes zoomOutFadeRotate {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes zoomOutFadeRotateReverse {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%) rotate(-135deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-135deg);
  }
}

@keyframes zoomOutFadeSpin {
  0% {
    opacity: 0;
    transform: scale(0.1) rotate(-180deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

@keyframes zoomInFade {
  0% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes flowIn {
  0% {
    margin-left: 100%;
    transform: rotate(-10deg);
  }

  100% {
    margin-left: 0%;
  }
}

@keyframes appearIn {
  0% {
    width: 0%; //need to change this into a translate refactor. Width animation is janky AF
  }

  100% {
    width: 100%;
  }
}

@keyframes morphOne {
  0% {
    transform: rotate(0deg) skew(0, 0);
  }

  25% {
    transform: rotate(90deg) skew(3deg, 2deg) scale(1.1);
  }

  50% {
    transform: rotate(179deg) skew(0deg, 0deg) scale(1);
  }

  75% {
    transform: rotate(269deg) skew(-1deg, -2deg) scale(0.9);
  }

  100% {
    transform: rotate(359deg) skew(0, 0);
  }
}

@keyframes morphTwo {
  0% {
    transform: rotate(0deg) skew(0, 0);
  }

  25% {
    transform: rotate(-90deg) skew(-3deg, -4deg) scale(1.25);
  }

  50% {
    transform: rotate(-179deg) skew(0deg, 0deg) scale(1);
  }

  75% {
    transform: rotate(-269deg) skew(5deg, 2deg) scale(0.95);
  }

  100% {
    transform: rotate(-359deg) skew(0, 0);
  }
}

@keyframes pulse {
  0%,
  30% {
    opacity: 0;
    transform: scale(0.5);
  }

  40% {
    opacity: 0.8;
    transform: scale(0.9);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes swiperSlideEnter {
  from {
    transform: translateX(100%);
  }
}

@keyframes swiperSlideExit {
  to {
    transform: translateX(-100%);
  }
}
