@import '../../../../assets/styles/settings/settings';
@import '../../../../assets/styles/utilities/utilities';
@import '../../../../assets/styles/generic/gen-keyframes';

.bubbles {
  max-width: 1300px;

  @include media-breakpoint-up(md) {
    min-height: 1024px;
  }

  @include media-breakpoint-between(md, lg) {
    @media only screen and (max-height: 1100px) {
      height: 100%;
      min-height: 100%;
      padding-top: 12rem;

      .bubbles__heading {
        margin-top: 0;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    min-height: 800px;
  }

  &__title {
    @include h2;
  }

  &__subtitle {
    @extend %text--subtitle;
  }

  &__description {
    font-size: 1.8rem;
    max-width: 606px;
  }

  &__svg {
    @include media-breakpoint-up(xl) {
      @include margin--auto;
      height: 500px;
    }
  }

  &__mobileList {
    background: var(--primary);
  }

  &__mobileItem {
    color: var(--secondary);
    font-size: 2.1rem;
    font-weight: bold;
    text-transform: capitalize;
  }

  &__bubble {
    circle {
      opacity: 0;
      transform: scale(0.5);

      :global(.swiper-slide-active) & {
        animation: pulse 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }

    @for $i from 1 through 6 {
      &:nth-child(#{$i}n) circle {
        animation-delay: #{$i * 0.2}s !important;
      }
    }
  }
}
