// Colours
$c-black: hsl(0, 0%, 0%);
$c-white: hsl(0, 0%, 100%);

//Primary Colors
$c-blue: hsl(256, 100%, 48%);
$c-aquamarine: hsl(169, 74%, 77%);
$c-citric: hsl(77, 88%, 68%);
$c-spotify-black: hsl(0, 11%, 9%);
$c-tangerine: hsl(6, 100%, 60%);
$c-fuschia: hsl(324, 86%, 58%);

$rgb-blue: 65, 0, 245;
$rgb-aquamarine: 153, 240, 224;
$rgb-citric: 205, 245, 102;
$rgb-spotify-black: 25, 20, 20;
$rgb-tangerine: 255, 71, 51;
$rgb-fuschia: 240, 56, 166;

//Secondary Colors
$c-secondary-green: hsl(127, 60%, 85%);
$c-secondary-blue: hsl(198, 36%, 73%);
$c-secondary-yellow: hsl(54, 95%, 58%);

// sass variables for default colors
$colors: (
  'black': $c-black,
  'white': $c-white,
  'blue': $c-blue,
  'aquamarine': $c-aquamarine,
  'citric': $c-citric,
  'spotify-black': $c-spotify-black,
  'tangerine': $c-tangerine,
  'fuschia': $c-fuschia,
  'secondary-green': $c-secondary-green,
  'secondary-blue': $c-secondary-blue,
  'secondary-yellow': $c-secondary-yellow,
);

// Variable prefix
$color-prefix: --c-;

// Color naming function
@function color($color-name) {
  @return var(#{$color-prefix}#{$color-name});
}
